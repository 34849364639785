import { Button, Dialog, Divider, Toast } from 'antd-mobile'
import React from 'react'
import './PromotePage.css'
import { getRealUrlApi } from '@/services/base'

export default function PromotePage() {
  const hour = new Date().getHours()
  async function handleStartChat() {
    const { code, data, msg } = await getRealUrlApi('test')
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    window.location.href = data
  }

  return (
    <div className="prmote">
      <h1 className="title">缘起聊天</h1>
      <hr />

      <div class="desc">
        <span>陌生人随机匿名聊天交友网站</span>
      </div>
      <div class="slogan">人生若只如初见</div>
      <div>
        <div className="start-chat-btn" style={{ textAlign: 'right' }}>
          <Button
            shape="rounded"
            color="warning"
            size="large"
            style={{ padding: '10px 26px' }}
            onClick={() => {
              window.location.href = 'https://yuanqilt.com/'
            }}>
            开始聊天
          </Button>
        </div>
      </div>

      <div style={{ textAlign: 'right' }}>
        <img className="introimg" src="/bg/intro.jpg" alt="" />
      </div>

      <p>
        缘起聊天是一款在线匿名聊天交友的网站，界面清晰简洁，有多种交友的方式，包括：随机匹配、捞漂流瓶、随机捞在线的人、分享链接；支持查看对方在线/离线状态，支持查看消息已读状态；支持发送语音、图片、视频；支持收藏、拉黑、备注好友；无需注册和下载App即可开始聊天。
      </p>
    </div>
  )
}
